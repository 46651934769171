import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'

import leagues from './leagues'
import matchups from './matchups'
import picks from './picks'
import user from './user'

const reducer = combineReducers({
  leagues,
  matchups,
  picks,
  user,
})

const store = configureStore({
  reducer,
})

export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch