import { useState } from 'react'
import { Modal, Image, Pressable, View, StyleSheet } from 'react-native'

import MatchupInfo from './MatchupInfo'

import { Colors } from '../styles/Colors'
import getMatchupIcon from './utils/getMatchupIcon'
import getMatchupIconColor from './utils/getMatchupIconColor'


function MatchupCenterIcon({ league, pickOutcome, pickSide, info, results }: { league: string, pickOutcome: string, pickSide: string, info: any, results: any }) {
    const [infoModal, setInfoModal] = useState(false)
    const inProgress = info.pickTimePast && !results
    
    return (
        <> 
            <View style={styles.iconContainer}>
                    {/* <MatchupInfo info={info} results={results} pickOutcome={pickOutcome} /> */}
                <View style={styles.iconCircleCutout}>
                    <Pressable
                        onPress={() => { setInfoModal(true) }}
                    >
                        <View style={[styles.iconBackground, { backgroundColor: getMatchupIconColor(pickOutcome, pickSide, results) }]}>
                            <Image source={getMatchupIcon(league, inProgress)} style={styles.centerIcon} />
                        </View>
                    </Pressable>
                </View>
            </View>
            <Modal
                animationType='slide'
                transparent={true}
                visible={infoModal}
            >
                <View></View>
            </Modal>
        </>

  )
}

const styles = StyleSheet.create({
    iconContainer: {
        position: 'absolute',
        left: '38%',
        top: 18,
    },
    iconCircleCutout: {
        borderRadius: 50,
        height: 64,
        width: 64,
        backgroundColor: Colors.PRIMARY_BACKGROUND,
        alignItems: 'center',
    },
    iconBackground: {
        backgroundColor: Colors.DEFAULT_COLOR,
        height: 50,
        width: 50,
        borderRadius: 50,
        alignItems: 'center',
        top: 7
    },
    centerIcon: {
        height: 32,
        width: 32,
        top: 9
    }
  })
    
export default MatchupCenterIcon