import { Pressable, Text, StyleSheet, View } from 'react-native'

import { Colors } from '../styles/Colors'

export function LeagueOverviewCard({ leagueId, leagueName, standings, onPress }: { leagueId: string, leagueName: string, standings: any, onPress: any }) {
    return (
        <Pressable
            style={styles.leagueCardContainer}
            onPress={onPress}
        >
            <View style={styles.leagueCardArea}>
                <Text style={styles.text1}>
                    {leagueName}
                </Text>
                {standings && standings[leagueId] && (
                    <View style={styles.overviewCard}>
                        {[...standings[leagueId]].sort((a: any, b: any) => b.wins - a.wins).slice(0, 3).map((userRecord: any, i: number) => (
                            <View key={`standings-${i}`}>
                                <Text style={styles.text1}>
                                    {userRecord.username}
                                </Text>
                                <Text style={styles.text1}>
                                    #{i+1} Rank
                                </Text>
                                <Text style={styles.text1}>
                                    {userRecord.record}
                                </Text>
                            </View>
                        ))}
                    </View>
                )}
            </View>
        </Pressable>
    )
  }

export function PublicLeagueCard({ league }: { league: any }) {
    return (
        <View key={`public-league-${league.id}`}>
            <View style={styles.leagueCardArea}>
                <Text style={styles.text1}>
                    {league.name}
                </Text>
                <Text style={styles.text1}>
                    {league.count} players
                </Text>
                <Pressable
                    onPress={() => {}}
                >
                    <Text>Join League</Text>
                </Pressable>
            </View>
        </View>
    )
}

export function LeagueCommissionerCard({ leagueId, leagueName, onPress }: { leagueId: string, leagueName: string, onPress: any }) {
    return (
        <Pressable
            style={{}}
            onPress={onPress}
        >
            <View style={styles.leagueCardArea}>
                <Text style={styles.text1}>
                    {leagueName}
                </Text>
            </View>
        </Pressable>
    )
}

const styles = StyleSheet.create({
    leagueCardContainer: {
        alignItems: 'center',
        width: '100%'
    },
    text1: {
        color: Colors.WHITE,
    },
    leagueCardArea: {
        marginTop: 5,
        marginBottom: 5,
        backgroundColor: Colors.UNSELECTED_COLOR,
        alignItems: 'center',
        width: '75%',
        borderRadius: 15
    },
    overviewCard: {
        flexDirection: 'row', 
        justifyContent: 'space-evenly'
    },
})
      