export enum Colors {
    PRIMARY_BACKGROUND = '#172438',
    MENU_BACKGROUND = '#0D192D',
    ACTION_PRIMARY = '#149BFE',
    UNSELECTED_COLOR = '#1D314C',
    DEFAULT_COLOR = '#149BFE',
    WIN_COLOR = '#3EC100',
    LOSS_COLOR = '#AE2001',
    PUSH_COLOR = '#9c9c9c',
    NO_PICK_COLOR = '#2e4d78',
    WHITE = '#FFFFFF',
    SPREAD_YELLOW = '#FFDD00',
    INACTIVE_TEXT = '#b2b2b2'
}
