import { createNativeStackNavigator } from '@react-navigation/native-stack'

import CommissionerView from './CommissionerView'
import LeaguesOverview from './LeaguesOverview'
import Standings from './Standings'


const Stack = createNativeStackNavigator()

function LeaguesOverviewNav() {
    return (
        <Stack.Navigator>
            <Stack.Screen
                name="LeaguesOverview"
                component={LeaguesOverview}
                options={{ headerShown: false }}
            />
            <Stack.Screen
                name="Standings"
                component={Standings}
                options={{ headerShown: false }}
            />
            <Stack.Screen
                name="Commissioner"
                component={CommissionerView}
                options={{ headerShown: false }}
            />
        </Stack.Navigator>
    )
}
    
export default LeaguesOverviewNav