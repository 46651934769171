import { Fragment } from 'react'
import { View, StyleSheet } from 'react-native'

import { Colors } from '../styles/Colors'

const MatchupCardSkeleton = () => {
    return (
        <Fragment>
            <View style={styles.skeletonHeader} />
            <View style={styles.skeletonCard} />
        </Fragment>
    )
}

const MatchupsSkeleton = () => {
    return (
        <View style={styles.skeletonView}>
            <MatchupCardSkeleton />
            <MatchupCardSkeleton />
            <MatchupCardSkeleton />
            <MatchupCardSkeleton />
            <MatchupCardSkeleton />
            <MatchupCardSkeleton />
        </View>
    )
}

const styles = StyleSheet.create({
    skeletonView: {
        flexDirection: 'column',
        width: '100%',
        maxWidth: 380,
        marginTop: 5
    },
    skeletonHeader: {
        height: 18,
        marginLeft: '3%',
        marginRight: '3%',
        marginBottom: 5,
        borderRadius: 10,
        backgroundColor: Colors.UNSELECTED_COLOR,
    },
    skeletonCard: {
        marginTop: 5,
        marginBottom: 15,
        marginLeft: '3%',
        marginRight: '3%',
        borderRadius: 10,
        height: 100,
        backgroundColor: Colors.UNSELECTED_COLOR,
    }
})

export default MatchupsSkeleton