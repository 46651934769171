import { useState } from 'react';
import { Pressable, Modal, View, SafeAreaView, Text, TextInput, StyleSheet } from 'react-native'

import Button from '../components/Button'
import ATSArenaLogo from '../components/ATSArenaLogo'
import CreateAccount from '../components/CreateAccount'
import { useAppDispatch } from '../store/hooks'
import { login } from '../store/user'
import { Colors } from '../styles/Colors'

const Login = () => {
  const dispatch = useAppDispatch()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [createAccount, setCreateAccount] = useState(false)
  const [loginLoading, setLoginLoading] = useState(false)
  
  const handleLogin = () => {
    setLoginLoading(true)
    dispatch(login(username, password))
  }

  const handleCreateAccount = () => {
    setCreateAccount(true)
  }

  return (
    <View style={styles.loginView}>
      <ATSArenaLogo />
      <View style={styles.inputContainer}>
        <Text style={[styles.text1, styles.label]}>Username / Email</Text>
        <TextInput
          style={styles.input}
          onChangeText={setUsername}
          value={username}
        />
        <Text style={[styles.text1, styles.label]}>Password</Text>
        <TextInput
          style={styles.input}
          onChangeText={setPassword}
          value={password}
          secureTextEntry={true}
        />
        <Button
          disabled={false}
          onPress={handleLogin}
          secondary={true}
          style={styles.loginButton}
          loading={loginLoading}
        >
          Login
        </Button>
        <Button
          disabled={false}
          onPress={handleCreateAccount}
          style={styles.createAccountButton}
        >
          Create Account
        </Button>
        <Modal
          animationType='slide'
          transparent={true}
          visible={createAccount}
        >
          <SafeAreaView style={styles.modalView}>
            <CreateAccount closeModal={() => setCreateAccount(false)}/>
          </SafeAreaView>
        </Modal>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  loginView: {
    backgroundColor: Colors.PRIMARY_BACKGROUND,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 8
  },
  text1: {
    color: Colors.WHITE
  },
  label: {
    alignItems: 'flex-start',
    width: '75%',
  },
  inputContainer: {
    width: '100%',
    alignItems: 'center',
    marginTop: 50,
  },
  input: {
    color: Colors.WHITE,
    fontSize: 22,
    borderWidth: 1,
    borderRadius: 6,
    margin: 10,
    padding: 5,
    width: '75%'
  },
  loginButton: {
    marginTop: 15,
    marginBottom: 5,
    width: 200
  },
  createAccountButton: {
    marginTop: 5,
    marginBottom: 5,
    width: 200
  },
  modalView: {
    top: 20,
    height: '100%',
    width: '100%',
    backgroundColor: Colors.PRIMARY_BACKGROUND
  }
})

export default Login