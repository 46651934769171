import { Pressable, Text, View, StyleSheet, ActivityIndicator } from 'react-native'
import moment from 'moment'

import { Colors } from '../styles/Colors'
import formatSpread from './utils/formatSpread'

const CommissionerMatchupCard = ({ matchup }: { matchup: any }) => {
    return (
        <View style={styles.commissionerMatchupCard} key={`commissioner-matchup-card-${matchup.id}`}>
            <View style={styles.cardPaddingContainer}>
                <View style={styles.cardContent}>
                    <View style={styles.teamNames}>
                        <Text style={styles.text1}>
                            {matchup.away_team.location} {matchup.away_team.mascot} {matchup.favorite === 'AWAY' ? <Text style={styles.spreadText}>{formatSpread(matchup.spread)}</Text> : ''}
                        </Text>
                        <Text style={styles.text1}>
                            @ {matchup.home_team.location} {matchup.home_team.mascot} {matchup.favorite === 'HOME' ? <Text style={styles.spreadText}>{formatSpread(matchup.spread)}</Text> : ''}
                        </Text>
                    </View>
                    <Text style={styles.text1}>{moment(matchup.start_time).local().format('MM/DD h:mm A')}</Text>
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    text1: {
        color: Colors.WHITE,
    },
    commissionerMatchupCard: {},
    cardPaddingContainer: {
        width: '100%',
        maxWidth: 380,
    },
    cardContent: {
        flexDirection: 'row',
        width: '100%',
        height: 100,
        backgroundColor: Colors.UNSELECTED_COLOR,
        borderRadius: 10,
    },
    teamNames: {
        flexDirection: 'column'
    },
    team: {
        flexDirection: 'row',
    },
    spreadText: {
        color: Colors.SPREAD_YELLOW
    }
})

export default CommissionerMatchupCard