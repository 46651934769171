import moment from 'moment'

const DAY_ADDITIONS = {
    0: 3,
    1: 2,
    2: 1,
    3: 0,
    4: 6,
    5: 5,
    6: 4
}
const getNextWed = () => {
    const today = moment()
    const nextWed = today.add(DAY_ADDITIONS[today.day()], 'days')
    return nextWed.format('YYYY-MM-DD')
}

export default getNextWed