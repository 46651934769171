import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Text, View, StyleSheet, Image, Pressable } from 'react-native'
import moment from 'moment'

import ChevronLeft from '../assets/chevronleft.png'
import ChevronRight from '../assets/chevronright.png'
import ChevronDown from '../assets/chevrondown.png'

import { Colors } from '../styles/Colors'


enum Directions {
    RIGHT = 'RIGHT',
    LEFT = 'LEFT'
}

function LeagueWeekDisplay({ activeId, activeWeek, leagues, setDropdownOpen, handleWeekChange }: { activeId: string, activeWeek: string, leagues: any, setDropdownOpen: any, handleWeekChange: any } ) {
    const [hydrated, setHydrated] = useState(false)
    const [activeLeague, setActiveLeague] = useState({
        id: '',
        name: '',
        leagueWeek: '',
        weeks: ['']
    })
    
    useEffect(() => {
        if (activeId) {
            setActiveLeague(leagues[activeId])
            setHydrated(true)
        }
    }, [activeId])

    const getDisplayWeeks = (): string[] => {
        let backWeek = ''
        let currentWeek = activeWeek
        let forwardWeek = ''
        const placement = activeLeague.weeks.indexOf(activeWeek)
        if (placement < activeLeague.weeks.length - 1) {
            forwardWeek = activeLeague.weeks[placement + 1]
        }
        if (placement > 0) {
            backWeek = activeLeague.weeks[placement - 1]
        }
        return [backWeek, currentWeek, forwardWeek]
    }

    const isChevronActive = (direction: string, weekName: string, activeWeek: string) => {
        const activeIndex = activeLeague.weeks.indexOf(weekName)
        if (direction === Directions.RIGHT) {
            return activeIndex < activeLeague.weeks.length - 1
        } else if (direction === Directions.LEFT) {
            return activeIndex > 0
        }
        return false
    }

    const handleChevronClick = (direction: string) => {
        const activeIndex = activeLeague.weeks.indexOf(activeWeek)
        if (direction === Directions.LEFT) handleWeekChange(activeLeague.weeks[activeIndex - 1])
        if (direction === Directions.RIGHT) handleWeekChange(activeLeague.weeks[activeIndex + 1])
    }

    const useRecord = (scope: string) => {
        const picksState = useSelector((state: any) => state.picks)
        if (!activeLeague || !picksState[activeLeague.id]) return ''
        const allPicks: any[] = []
        Object.keys(picksState[activeLeague.id]).forEach((weekKey: string) => {
            Object.values(picksState[activeLeague.id][weekKey]).forEach((pick) => {
                allPicks.push(pick)
            })
        })
        const picksThisWeek = picksState[activeLeague.id][activeWeek]
        const picks = scope === 'OVERALL' ? allPicks : picksThisWeek
        let wins = 0
        let losses = 0
        let pushes = 0
        if (!picks) return
        Object.values(picks).forEach((pick: any) => {
            if (!pick.outcome) return
            if (pick.outcome.outcome === 'WIN') wins++
            if (pick.outcome.outcome === 'LOSS') losses++
            if (pick.outcome.outcome === 'PUSH') pushes++
        })
        if (pushes > 0) return `${wins}-${losses}-${pushes}`
        return `${wins}-${losses}`
    }

    return (
        <View style={styles.leagueWeekContainer}>
            <Pressable
                style={styles.leagueNameSelectionContainer}
                onPress={() => setDropdownOpen(true)}
            >
                <Text style={styles.leagueName}>{activeLeague.name}</Text>
                <Image source={ChevronDown} style={styles.chevron} />
            </Pressable>
            <View style={styles.weekInfoContainer}>
                <View style={styles.weekInfoBlock}>
                    <Text style={styles.text1}>-</Text>
                    <Text style={styles.text1}>Rank</Text>
                </View>
                <View style={styles.weekInfoBlock}>
                    <Text style={styles.text1}>{useRecord('WEEK')}</Text>
                    <Text style={styles.text1}>This Round</Text>
                </View>
                <View style={styles.weekInfoBlock}>
                    <Text style={styles.text1}>{useRecord('OVERALL')}</Text>
                    <Text style={styles.text1}>Overall</Text>
                </View>
            </View>
            {hydrated && (
                <View style={styles.weeksContainer}>
                    {getDisplayWeeks().map((weekName: string, index: number) => (
                        <View style={styles.weekNameContainer} key={`week-${weekName}-${index}`}>
                            <View>
                                {index === 1 && (
                                    <Pressable
                                        onPress={() => { 
                                            if(isChevronActive(Directions.LEFT, weekName, activeWeek)) handleChevronClick(Directions.LEFT) 
                                        }}
                                    >
                                        <Image source={ChevronLeft} style={[styles.chevron, !isChevronActive(Directions.LEFT, weekName, activeWeek) && styles.inactiveChevron]} />
                                    </Pressable>
                                )}
                            </View>
                            <View style={styles.weekName}>
                                <Pressable
                                    onPress={() => { 
                                        if (index === 0) handleChevronClick(Directions.LEFT)
                                        if (index === 2) handleChevronClick(Directions.RIGHT)
                                    }}
                                >
                                    <Text style={index === 1 ? styles.text1 : styles.inactiveText}>{weekName}</Text>
                                </Pressable>
                            </View>
                            <View>
                                {index === 1 && (
                                    <Pressable
                                        onPress={() => { 
                                            if(isChevronActive(Directions.RIGHT, weekName, activeWeek)) handleChevronClick(Directions.RIGHT) 
                                        }}
                                    >
                                        <Image source={ChevronRight} style={[styles.chevron, !isChevronActive(Directions.RIGHT, weekName, activeWeek) && styles.inactiveChevron]} />
                                    </Pressable>
                                )}
                            </View>
                        </View>
                    ))}
                </View>
            )}
        </View>

    )
}

const styles = StyleSheet.create({
    leagueWeekContainer: {
        marginTop: 5,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        maxWidth: 380
    },
    text1: {
        color: Colors.WHITE
    },
    inactiveText: {
        color: Colors.INACTIVE_TEXT
    },
    leagueNameSelectionContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.MENU_BACKGROUND,
        width: '90%',
        borderRadius: 25
    },
    leagueSelection: {
        position: 'absolute',
        backgroundColor: 'red',
        top: 0,
        width: 250,
        height: 150
    },
    leagueName: {
        color: Colors.WHITE,
        fontSize: 24
    },
    weekNameContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '30%'
    },
    weekName: {
        marginLeft: 5,
        marginRight: 5
    },
    weekInfoContainer: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 5,
        marginBottom: 5
    },
    weekInfoBlock: {
        flexDirection: 'column',
        alignItems: 'center',
        width: '25%'
    },
    weeksContainer: {
        flexDirection: 'row',
        backgroundColor: Colors.MENU_BACKGROUND,
        justifyContent: 'center',
        borderRadius: 25,
        paddingTop: 3,
        paddingBottom: 3,
        width: '90%'
    },
    chevron: {
        width: 20,
        height: 20,
    },
    inactiveChevron: {
        tintColor: Colors.MENU_BACKGROUND,
    }
  })
  
export default LeagueWeekDisplay
