import { mutate, query } from './clients/HasuraClient'
import insertUserMutation from './graphQL/insertUserMutation'


export const insertUser = (username: string, email: string, sub: string) => {
    return new Promise((res, rej) => {
        mutate(insertUserMutation, { username, email, auth0_sub: sub }).then((response: any) => {
            if (response.data) {
                res(response.data)
            } else if (response.errors) {
                rej(response.errors)
            }
        })
    })
}
