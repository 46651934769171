import Toast, { BaseToast, ToastConfig } from 'react-native-toast-message'
import { Colors } from './Colors'

const toastConfig: ToastConfig = {
  success: (props) => {return (
    <BaseToast
      {...props}
      style={{
        backgroundColor: Colors.UNSELECTED_COLOR,
        borderLeftColor: Colors.ACTION_PRIMARY,
      }}
      text1Style={{
        color: Colors.WHITE
      }}
    />
  )}
}

export default toastConfig