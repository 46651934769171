import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { insertPicks } from '../api/picksAPI'

interface PicksStateType {
  [leagueId: string]: {
    [leagueWeek: string]: {
      [matchupId: string]: {
        pickTeamId: string
        outcome?: OutcomeInfoType
      }
    }
  }
}

interface OutcomeInfoType {
  outcome: string
  cover: boolean
  upset: boolean
  push: boolean
}

interface SetPicksPayloadType {
  leagueId: string
  picks: any
}

interface MakePickPayloadType {
  leagueId: string
  activeWeek: string
  matchupId: string
  pickTeamId: string
}

// Slice
const slice = createSlice({
  name: 'picks',
  initialState: {},
  reducers: {
    setPicks: (state: PicksStateType, action: PayloadAction<SetPicksPayloadType>) => {
      state[action.payload.leagueId] = action.payload.picks
    },
    makePick: (state: PicksStateType, action: PayloadAction<MakePickPayloadType>) => {
      state[action.payload.leagueId][action.payload.activeWeek][action.payload.matchupId] = { pickTeamId: action.payload.pickTeamId }
    }
  },
});

export default slice.reducer
export const actions = slice.actions

// Actions
const { makePick } = slice.actions

export const makeSelection = (matchupId: string, pickTeamId: string, leagueId: string, activeWeek: string) => (dispatch: any) => {
  dispatch(makePick({ matchupId, pickTeamId, leagueId, activeWeek }))
}

export const savePicks = (picks: any, leagueId: string, userId: string) => (dispatch: any) => {
  insertPicks(picks, leagueId, userId)
}
