const HASURA_BASE_URL = 'https://pickem-hasura.herokuapp.com/v1/graphql';

interface BodyObjType {
    query: string
    variables?: any
}

export const query = (query: any, variables?: any) => {
    return new Promise(resolve => {
        const bodyObj: BodyObjType = {
            query,
        }
        if (variables) bodyObj.variables = variables
        fetch(HASURA_BASE_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(bodyObj)
        })
        .then(r => r.json())
        .then(data => resolve(data));
    })
}

export const mutate = (mutation: any, variables: any) => {
    return new Promise(resolve => {
        fetch(HASURA_BASE_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                query: mutation,
                variables
            })
        })
        .then(r => r.json())
        .then(data => resolve(data));
    })
}
