import { Pressable, Text, StyleSheet } from 'react-native'
import * as SecureStore from 'expo-secure-store'

import Button from './Button'
import { useAppDispatch } from '../store/hooks'
import { logout } from '../store/user'
import { Colors } from '../styles/Colors'

function LogoutButton() {
    const dispatch = useAppDispatch()

    const handleLogout = async () => {
        try {
            await SecureStore.deleteItemAsync('atsarenalogintoken')
        } catch {
            localStorage.removeItem('atsarenalogintoken')
        }
        dispatch(logout())
    }

    return (
        <Button
            onPress={handleLogout}
            disabled={false}
            style={styles.logoutButton}
        >
            Logout
        </Button>
    )
}

const styles = StyleSheet.create({
    text1: {
        color: Colors.WHITE
    },
    logoutButton: {
        width: 150,
    },
})

export default LogoutButton
