import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FlatList, Pressable, Text, StyleSheet, View } from 'react-native'

import { useAppDispatch } from '../store/hooks'
import { hydrateLeaguesOverview, joinLeague } from '../store/leagues'
import { LeagueOverviewCard, PublicLeagueCard, LeagueCommissionerCard } from '../components/LeagueCard'
import { Colors } from '../styles/Colors'


function LeaguesOverview({ navigation }: { navigation: any }) {
    const dispatch = useAppDispatch()
    const { id: userId } = useSelector((state: any) => state.user)
    const { userLeagues, publicLeagues, standings } = useSelector((state: any) => state.leagues)
    const userLeaguesArr: any[] = Object.values(userLeagues)
    const commissionerLeaguesArr = userLeaguesArr.filter((league) => league.commissionerId === userId)
    const publicLeaguesArr = Object.values(publicLeagues).filter((league: any) => !Object.keys(userLeagues).includes(league.id))

    useEffect(() => {
        dispatch(hydrateLeaguesOverview(Object.keys(userLeagues)))
    }, [])

    const handleJoinLeague = (leagueId: string) => {
        dispatch(joinLeague(userId, leagueId))
    }

    return (
        <View style={styles.leaguesView}>
            {commissionerLeaguesArr && commissionerLeaguesArr.length > 0 && (
                <View style={styles.leagueCardContainer}>
                    <Text style={styles.headerText}>My Commissioner Leagues</Text>
                    <FlatList
                        data={userLeaguesArr}
                        renderItem={(league: any) => (
                            <LeagueCommissionerCard
                                leagueId={league.item.id}
                                leagueName={league.item.name}
                                onPress={() => { navigation.navigate('Commissioner', {
                                    id: league.item.id,
                                    name: league.item.name
                                }) }}
                            />
                        )}
                        // keyExtractor={league => league.id}
                    />
                </View>                 
            )}
            {userLeaguesArr && userLeaguesArr.length > 0 && (
                <View style={styles.leagueCardContainer}>
                    <Text style={styles.headerText}>My Leagues</Text>
                    <FlatList
                        data={userLeaguesArr}
                        renderItem={(league: any) => (
                            <LeagueOverviewCard
                                leagueId={league.item.id}
                                leagueName={league.item.name}
                                standings={standings}
                                onPress={() => { navigation.navigate('Standings', { id: league.item.id }) }}
                            />
                        )}
                        // keyExtractor={league => league.id}
                    />
                </View>                 
            )}
            <Text style={styles.headerText}>Public Leagues</Text>
            {(publicLeaguesArr && publicLeaguesArr.length > 0) ? (
                publicLeaguesArr.map((publicLeague: any) => (
                    <PublicLeagueCard
                        league={publicLeague}
                        key={`public-league-card-${publicLeague.id}`}
                    />
                )
            )) : (
                <Text style={styles.text1}>
                    No Public Leagues Available
                </Text>
            )}
        </View>
    )
}

const styles = StyleSheet.create({
    leaguesView: {
        flex: 8,
        backgroundColor: Colors.PRIMARY_BACKGROUND,
        alignItems: 'center'
    },
    leagueCardContainer: {
        width: '100%',
        maxWidth: 380
    },
    headerText: {
        fontSize: 24,
        color: Colors.WHITE
    },
    text1: {
        color: Colors.WHITE
    },
    leagueCardArea: {
        // height: 10,
        // width: 400,
        // backgroundColor: Colors.UNSELECTED_COLOR
    }
})

export default LeaguesOverview