import { useState } from 'react'
import { View, Text, TextInput, StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'
import Button from '../components/Button'
import LogoutButton from '../components/LogoutButton'

import { Colors } from '../styles/Colors'


const Profile = () => {
  const { username, email, id } = useSelector((state: any) => state.user)
  const [newUsername, setNewUsername] = useState(username)

  const handleSubmit = () => {

  }

  return (
    <View style={styles.profileView}>
        <View style={styles.profileCard}>
          <View style={styles.usernameRow}>
            <TextInput
              style={styles.input}
              onChangeText={setNewUsername}
              value={newUsername}
            />
            <Button
              disabled={false}
              onPress={handleSubmit}
              style={styles.saveButton}
            >
              Save
            </Button>
          </View>
          <Text style={styles.text1}>Email: {email}</Text>
        </View>
        <LogoutButton />
    </View>
  )
}

const styles = StyleSheet.create({
  profileView: {
    backgroundColor: Colors.PRIMARY_BACKGROUND,
    flex: 8,
    alignItems: 'center',
    paddingTop: 15,
  },
  input: {
    color: Colors.WHITE,
    fontSize: 22,
    borderWidth: 1,
    borderRadius: 6,
    margin: 10,
    padding: 5
  },
  text1: {
    color: Colors.WHITE
  },
  usernameRow: {
    flexDirection: 'row'
  },
  profileCard: {
    backgroundColor: Colors.UNSELECTED_COLOR,
    alignItems: 'center',
    width: '75%',
    borderRadius: 15,
    maxWidth: 380
  },
  saveButton: {
    width: 70
  }
})

export default Profile