import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Text, View, StyleSheet, Image } from 'react-native'

import ChevronLeft from '../assets/chevronleft.png'
import { useAppDispatch } from '../store/hooks'
import { hydrateLeaguesOverview } from '../store/leagues'
import { Colors } from '../styles/Colors'
import BackButton from '../components/BackButton'


function Standings({ navigation, route }: { navigation: any, route: any }) {
  const { standings } = useSelector((state: any) => state.leagues)
  const safeLeagueId = route?.params?.id || ''
  
  return (
    <View style={styles.standingsView}>
      {standings && standings[safeLeagueId] && (
          <View>
              {[...standings[safeLeagueId]].sort((a: any, b: any) => b.wins - a.wins).map((userRecord: any, i: number) => (
                  <View style={styles.standingsContainer} key={`standings-key-${userRecord.username}`}>
                      <Text style={styles.text1}>
                          {userRecord.username}
                      </Text>
                      <Text style={styles.text1}>
                          #{i+1} Rank
                      </Text>
                      <Text style={styles.text1}>
                          {userRecord.record}
                      </Text>
                  </View>
              ))}
          </View>
      )}
      <BackButton navigation={navigation} />
    </View>
  )
}

const styles = StyleSheet.create({
    standingsView: {
        backgroundColor: Colors.PRIMARY_BACKGROUND,
        flex: 8,
        paddingTop: 50,
    },
    text1: {
        color: Colors.WHITE
    },
    standingsContainer: {

    }
})
  
export default Standings
