import { Image, StyleSheet } from 'react-native'

import ATSArena from '../assets/atsarenalogo.png'


const ATSArenaLogo = () => {
    return (
        <Image source={ATSArena} style={styles.image} />
    )
}

const styles = StyleSheet.create({
    image: {
        width: 230,
        height: 100
    }
})

export default ATSArenaLogo