import { Provider } from 'react-redux'
import { useFonts } from 'expo-font'
import Toast from 'react-native-toast-message'

import store from './src/store'
import MainContent from './src/views/MainContent'
import toastConfig from './src/styles/toastConfig'


export default function App() {
  const [fontsLoaded] = useFonts({
    'Poppins': require('./assets/fonts/Poppins/Poppins-Regular.ttf'),
  })

  return (
      <Provider store={store}>
        <MainContent />
        <Toast config={toastConfig} />
      </Provider >
  )
}
