import { Text, View, StyleSheet, Pressable } from 'react-native'
import moment from 'moment'

import MatchupCenterIcon from './MatchupCenterIcon'
import MatchupInfo from './MatchupInfo'
import { Colors } from '../styles/Colors'
import getBorderColor from './utils/getBorderColor'
import getPickSide from './utils/getPickSide'
import formatSpread from './utils/formatSpread'


function MatchupCard({ matchup, makeSelection, activePickId }: { matchup: any, makeSelection: any, activePickId: string }) {
    const pickSide = getPickSide(matchup?.away_team?.id, matchup?.home_team.id, activePickId)
    const pickTimePast = Date.now() >= new Date(matchup.start_time).getTime()
    const matchupResults = matchup.matchup_results && matchup.matchup_results[0] ? matchup.matchup_results[0] : null

    return (
        <View style={styles.matchupContainer}>
            <View style={styles.cardPaddingContainer}>
                <View style={styles.matchupHeader}>
                    <Text style={styles.matchupInfo}>{`${moment(matchup.start_time).local().format('MM/DD h:mm A')}`}</Text>
                    <MatchupInfo
                        results={matchupResults}
                        pickOutcome={matchup.picks[0]?.outcome}
                    />
                </View>
                <View style={styles.matchupCard}>
                    <Pressable
                        style={[styles.selectionArea, styles.rightPadding, { borderColor: getBorderColor(pickSide, matchup.picks[0]?.outcome, 'AWAY', matchupResults)}, { backgroundColor: pickSide === 'AWAY' ? matchup.away_team.color : Colors.UNSELECTED_COLOR }]}
                        onPress={() => {
                            if (!pickTimePast) makeSelection(matchup.id, matchup.away_team.id)
                        }}
                    >
                        <Text style={styles.text1}>
                            {matchup.away_team.location}
                        </Text>
                        <Text style={styles.text1}>
                            {matchup.away_team.mascot} {matchup.favorite === 'AWAY' ? <Text style={styles.spreadText}>{formatSpread(matchup.spread)}</Text> : ''}
                        </Text>
                        {matchupResults && (
                            <Text style={styles.scoreText}>
                                {matchupResults.away_team_score}
                            </Text>
                        )}
                    </Pressable>
                    <View style={styles.centerIconContainer} />
                    <Pressable
                        style={[styles.selectionArea, styles.leftPadding, { borderColor: getBorderColor(pickSide, matchup.picks[0]?.outcome, 'HOME', matchupResults)}, { backgroundColor: pickSide === 'HOME' ? matchup.home_team.color : Colors.UNSELECTED_COLOR }]}
                        onPress={() => {
                            if (!pickTimePast) makeSelection(matchup.id, matchup.home_team.id)
                        }}
                    >
                        <Text style={styles.text1}>
                            {matchup.home_team.location}
                        </Text>
                        <Text style={styles.text1}>
                            {matchup.home_team.mascot} {matchup.favorite === 'HOME' ? <Text style={styles.spreadText}>{formatSpread(matchup.spread)}</Text> : ''}
                        </Text>
                        {matchupResults && (
                            <Text style={styles.scoreText}>
                                {matchupResults.home_team_score}
                            </Text>
                        )}
                    </Pressable>
                    <MatchupCenterIcon
                        league={matchup.league}
                        pickOutcome={matchup.picks[0]?.outcome}
                        pickSide={pickSide}
                        info={{
                            pickTimePast,
                            startTime: matchup.start_time
                        }}
                        results={matchupResults}
                    />
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    matchupContainer: {
        marginTop: 5,
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center'
    },
    cardPaddingContainer: {
        width: '100%',
        maxWidth: 380,
    },
    matchupHeader: {
        height: 18,
        marginLeft: '3%',
        marginRight: '3%',
        marginBottom: 2,
        borderRadius: 10,
        backgroundColor: Colors.UNSELECTED_COLOR,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    }, 
    matchupInfo: {
        marginLeft: 5,
        color: Colors.WHITE
    },
    matchupCard: {
        flex: 1,
        flexDirection: 'row',
        width: '100%',
        marginTop: 3,
        marginBottom: 2,
        marginLeft: '3%',
        marginRight: '3%',
    },
    selectionArea: {
        width: '45%',
        alignItems: 'center',
        justifyContent: 'center',
        height: 100,
        backgroundColor: Colors.UNSELECTED_COLOR,
        borderRadius: 10,
        borderWidth: 4,
        borderStyle: 'solid',
        borderColor: Colors.UNSELECTED_COLOR
    },
    leftPadding: {
        paddingLeft: 10
    },
    rightPadding: {
        paddingRight: 10
    },
    centerIconContainer: {
        width: '4%'
    },
    text1: {
        color: Colors.WHITE,
        textShadowColor: 'rgba(0, 0, 0, 0.5)',
        textShadowOffset: {
            width: 1,
            height: 1
        },
        textShadowRadius: 2
    },
    scoreText: {
        color: Colors.WHITE,
        fontWeight: 'bold',
        textShadowColor: 'rgba(0, 0, 0, 0.5)',
        textShadowOffset: {
            width: 1,
            height: 1
        },
        textShadowRadius: 2
    },
    spreadText: {
        color: Colors.SPREAD_YELLOW
    }
  })
  
export default MatchupCard
