import { View, Pressable, Text, Image, StyleSheet } from 'react-native'
import { Colors } from '../styles/Colors'

import ChevronUp from '../assets/chevronup.png'

const LeagueSelectionDropdown = ({ setDropdownOpen, leagues, handleSelect, leagueName }: { setDropdownOpen: any, leagues: any[], handleSelect: any, leagueName: string }) => {
    return (
        <View style={styles.leagueSelection}>
            <Pressable
                style={styles.leagueNameSelectionContainer}
                onPress={() => setDropdownOpen(false)}
            >
                <Text style={styles.leagueName}>{leagueName}</Text>
                <Image source={ChevronUp} style={styles.chevron} />
            </Pressable>
            {Object.values(leagues).map((league: any) => (
                <Pressable
                    key={`league-${league.id}`}
                    style={styles.option}
                    onPress={() => { handleSelect(league.id) }}
                >
                    <Text style={styles.optionText}>{league.name}</Text>
                </Pressable>
            ))}
        </View>
    )
}

const styles = StyleSheet.create({
    optionText: {
        color: Colors.WHITE,
        fontSize: 20
    },
    leagueNameSelectionContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: 250
    },
    leagueSelection: {
        position: 'absolute',
        alignItems: 'center',
        backgroundColor: Colors.MENU_BACKGROUND,
        top: 5,
        width: '90%',
        maxWidth: 340,
        borderRadius: 25
    },
    option: {
        marginTop: 5,
        marginBottom: 5
    },
    leagueName: {
        color: Colors.WHITE,
        fontSize: 24
    },
    chevron: {
        width: 20,
        height: 20
    }
})

export default LeagueSelectionDropdown