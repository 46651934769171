import { Fragment, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Text, RefreshControl, StyleSheet, View, FlatList } from 'react-native'
import Toast from 'react-native-toast-message'

import MatchupCard from '../components/MatchupCard'
import LeagueWeekDisplay from '../components/LeagueWeekDisplay'
import Button from '../components/Button'
import { Colors } from '../styles/Colors'
import { useAppDispatch } from '../store/hooks'
import { hydrateMatchups } from '../store/matchups'
import { hydrateLeagueWeeks } from '../store/leagues'
import { makeSelection, savePicks } from '../store/picks'
import LeagueSelectionDropdown from '../components/LeagueSelectionDropdown'
import MatchupsSkeleton from '../components/MatchupsSkeleton'


function Matchups() {
  // Info from store
  const dispatch = useAppDispatch()
  const picksState = useSelector((state: any) => state.picks)
  const { id: userId } = useSelector((state: any) => state.user)
  const { userLeagues: leagues } = useSelector((state: any) => state.leagues)
  // Local component state
  const [activeLeague, setActiveLeague] = useState<any>({})
  const [activeWeek, setActiveWeek] = useState('')
  const [submitActive, setSubmitActive] = useState(false)
  const [needsLeagues, setNeedsLeagues] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [leagueWeeksHydrated, setLeagueWeeksHydrated] = useState(false)
  const [refreshing, setRefreshing] = useState(false)

  useEffect(() => {
    const firstLeague: any = Object.values(leagues)[0]
    if (firstLeague) {
      setActiveLeague(firstLeague)
      setActiveWeek(firstLeague.leagueWeek)
      setNeedsLeagues(false)
    } else {
      setNeedsLeagues(true)
    }
  }, [leagues])

  useEffect(() => {
    if (userId && activeLeague && activeLeague.id) {
      dispatch(hydrateMatchups(userId, activeLeague.id))
    }
  }, [userId, activeLeague])

  useEffect(() => {
    if (!leagueWeeksHydrated && activeLeague && activeLeague.id) {
      dispatch(hydrateLeagueWeeks(activeLeague.id))
      setLeagueWeeksHydrated(true)
    }
  }, [leagueWeeksHydrated])

  const handleLeagueChange = (leagueId: string) => {
    const newLeague = leagues[leagueId]
    setActiveLeague(newLeague)
    setActiveWeek(newLeague.leagueWeek)
  }

  const handleLeagueSelect = (leagueId: string) => {
    handleLeagueChange(leagueId)
    setActiveLeague(leagues[leagueId])
    setDropdownOpen(false)
  }

  const getActivePick = (matchupId: string) => {
    if (activeLeague && activeLeague.id && picksState[activeLeague.id]) {
      return picksState[activeLeague.id][activeWeek][matchupId]
    }
    return ''
  }

  const handlePickSelection = (matchupId: any, teamId: any) => {
    setSubmitActive(true)
    dispatch(makeSelection(matchupId, teamId, activeLeague.id, activeWeek))
  }

  const handleSubmit = () => {
    setSubmitActive(false)
    Toast.show({
      type: 'success',
      text1: 'Picks saved!',
      position: 'bottom'
      // text2: 'Picks saved!'
    })
    if (this && this.flatListRef) this.flatListRef.scrollToIndex({ animated: true, index: 0 })
    dispatch(savePicks(picksState[activeLeague.id][activeWeek], activeLeague.id, userId))
  }

  // TODO getters/computed properties best practices
  const useActiveMatchups = (leagueId: string) => {
    const matchupsState = useSelector((state: any) => state.matchups)
    if (!leagueId || activeWeek === '' || !matchupsState.infoByLeague[leagueId] || !matchupsState.infoByLeague[leagueId].matchupsByWeek[activeWeek]) return []
    return [...matchupsState.infoByLeague[leagueId].matchupsByWeek[activeWeek]].sort((a: any, b: any) => a.startTime - b.startTime)
  }

  const onRefresh = () => {
    setRefreshing(true)
    dispatch(hydrateMatchups(userId, activeLeague.id))
    setTimeout(() => {
      setRefreshing(false)
    }, 1000)
  }

  const activeMatchups = useActiveMatchups(activeLeague.id)
  return (
    <View style={styles.matchupsView}>
      {needsLeagues ? (
        <Text>needs leagues</Text>
      ) : (
        <View style={styles.matchupsContentContainer}>
          <LeagueWeekDisplay
            activeId={activeLeague.id}
            activeWeek={activeWeek}
            leagues={leagues}
            setDropdownOpen={setDropdownOpen}
            handleWeekChange={setActiveWeek}
          />
          {(activeMatchups && activeMatchups.length > 0) ? (
            <Fragment>
              <FlatList
                style={styles.matchupsList}
                data={activeMatchups}
                ref={(ref) => { if (this) this.flatListRef = ref }}
                refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
                renderItem={(matchup: any) => (
                  <MatchupCard
                    matchup={matchup.item}
                    makeSelection={handlePickSelection}
                    activePickId={getActivePick(matchup.item.id).pickTeamId}
                  />
                )}
                keyExtractor={matchup => matchup.id}
              />
              <Button
                disabled={!submitActive}
                onPress={handleSubmit}
                style={styles.submitButton}
              >
                Submit
              </Button>
            </Fragment>
          ) : (
            <MatchupsSkeleton />
          )}
          {dropdownOpen && (
            <LeagueSelectionDropdown
                leagueName={activeLeague.name}
                setDropdownOpen={setDropdownOpen}
                handleSelect={handleLeagueSelect}
                leagues={leagues}
            />
          )}
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  matchupsView: {
    flex: 10,
    width: '100%',
    alignItems: 'center',
    backgroundColor: Colors.PRIMARY_BACKGROUND,
    paddingTop: 10
  },
  text1: {
    color: '#FFFFFF'
  },
  matchupsContentContainer: {
    alignItems: 'center',
    width: '100%',
    paddingBottom: 10,
    flex: 1
  },
  matchupsList: {
    width: '100%',
  },
  submitButton: {
    width: 110
  }
})

export default Matchups
