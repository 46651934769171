import { Pressable, Image, StyleSheet } from 'react-native'

import ChevronLeft from '../assets/chevronleft.png'


const BackButton = ({ navigation }: { navigation: any }) => {
    return (
        <Pressable
            style={styles.backButtonBontainer}
            onPress={() => { navigation.goBack() }}
        >
            <Image source={ChevronLeft} style={styles.image} />
        </Pressable>
    )
}

const styles = StyleSheet.create({
    backButtonBontainer: {
        position: 'absolute',
        backgroundColor: 'red'
    },
    image: {
        width: 25,
        height: 25
    }
})

export default BackButton