import { View, Text, StyleSheet } from 'react-native'
import { Colors } from '../styles/Colors'

interface OutcomeInfoType {
    pickOutcome: string
    formattedResults?: {
        cover: boolean
        upset: boolean
        push: boolean
    }
}

const getOutcomeExplainer = ({ pickOutcome, formattedResults }: { pickOutcome: string, formattedResults: any }) => {
    const { push, cover, upset } = formattedResults
    if (!pickOutcome) return 'The matchup has concluded'
    if (push) {
        return 'The winning score minus the spread was equal to the losing score'
    } else if (pickOutcome === 'WIN') {
        if (cover) return 'Your pick won and covered the spread!'
        if (upset) return 'Your underdog pick won outright!'
        return 'Your pick beat the spread!'
    } else {
        if (upset) return 'Your favored pick lost outright.'
        if (cover) return 'Your underdog pick didn\'t score enough to beat the spread'
        return 'Your pick won but didn\'t score enough to cover the spread'
    }
}

const getOutcome = ({ pickOutcome, formattedResults }: { pickOutcome: string, formattedResults: any }) => {
    const { push, cover, upset } = formattedResults
    if (!pickOutcome) return ''
    if (push) {
        return ['PUSH', 'push']
    } else if (pickOutcome === 'WIN') {
        if (cover) return ['COVER', 'win']
        if (upset) return ['WIN', 'win']
        return ['BEAT SPREAD', 'win']
    } else {
        if (upset) return ['LOSS', 'loss']
        if (cover) return ['DIDN\'T BEAT SPREAD', 'loss']
        return ['DIDN\'T COVER', 'loss']
    }
}

const formatInfo = (info: any): string => {
    return new Date(info.startTime).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit'})
}

function MatchupInfo({ results, pickOutcome }: { results: any, pickOutcome: any }) {
    const formattedResults = results ? {
        cover: results.cover_spread,
        upset: results.upset,
        push: results.push
    } : null
    return (
        <View>
            <View>
                {formattedResults ? (
                    <Text style={styles[getOutcome({ pickOutcome, formattedResults })[1]]}>
                        {getOutcome({ pickOutcome, formattedResults })[0]}
                    </Text>
                ): (
                    <Text></Text>
                )}
            </View>
        </View>
    )
}
  
const styles = StyleSheet.create({
    push: {
        color: Colors.PUSH_COLOR
    },
    win: {
        color: Colors.WIN_COLOR
    },
    loss: {
        color: Colors.LOSS_COLOR
    }
})

export default MatchupInfo
