import FootballIcon from '../../assets/football.png'
import BasketballIcon from '../../assets/basketball.png'
import HockeyIcon from '../../assets/hockey.png'
import LockIcon from '../../assets/lock.png'
import RocketLeagueIcon from '../../assets/rocket-league.png'
import BaseballIcon from '../../assets/baseball.png'

export default (league: String, inProgress: boolean) => {
    if (inProgress) return LockIcon
    if (league === 'NFL' || league === 'NCAAF') return FootballIcon
    if (league === 'NBA' || league === 'NCAAM' || league === 'NCAAW') return BasketballIcon
    if (league === 'NHL') return HockeyIcon
    if (league === 'MLB') return BaseballIcon
    return BasketballIcon
}