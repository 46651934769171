import { Colors } from "../../styles/Colors"

export default (pickSide: string, pickOutcome: string, team: string, matchupResults?: any) => {
    if (!pickSide && !matchupResults) return Colors.UNSELECTED_COLOR
    if (!pickSide && matchupResults) {
        if (team === 'AWAY' && matchupResults.away_team_score > matchupResults.home_team_score) return Colors.NO_PICK_COLOR
        if (team === 'HOME' && matchupResults.home_team_score > matchupResults.away_team_score) return Colors.NO_PICK_COLOR
    }
    if (pickSide === team) {
        if (pickOutcome === 'WIN') return Colors.WIN_COLOR
        if (pickOutcome === 'LOSS') return Colors.LOSS_COLOR
        if (pickOutcome === 'PUSH') return Colors.PUSH_COLOR
        return Colors.DEFAULT_COLOR
    }
    return Colors.UNSELECTED_COLOR
}