export default `query CommissionerMatchups($leagueWeek: String!, $leagues:[String!]!) {
    matchups(where: {league_week: {_eq: $leagueWeek}, league: {_in: $leagues}}) {
      id
      league
      start_time
      home_team {
        id
        location
        mascot
      }
      away_team {
        id
        location
        mascot
      }
      favorite
      spread
    }
  }
  `