import { query } from './clients/HasuraClient'
import matchupsQuery from './graphQL/matchupsQuery'
import comissionerMatchupsQuery from './graphQL/commissionerMatchupsQuery'

export const fetchMatchups = (userId: string, leagueId: string) => {
    return new Promise((res, rej) => {
        query(matchupsQuery, { userId, leagueId }).then((response: any) => {
            if (response.data) {
                res(response.data.matchups)
            } else if (response.errors) {
                rej(response.errors)
            }
        })
    })
}

export const fetchCommissionerMatchups = (leagueWeek: string, leagues: string[]) => {
    return new Promise((res, rej) => {
        query(comissionerMatchupsQuery, { leagueWeek, leagues }).then((response: any) => {
            if (response.data) {
                res(response.data.matchups)
            } else if (response.errors) {
                rej(response.errors)
            }
        })
    })
}