import { Pressable, Text, StyleSheet, ActivityIndicator } from 'react-native'

import { Colors } from '../styles/Colors'


function Button({ secondary, disabled, onPress, children, style, loading = false }: { secondary?: boolean, disabled: boolean, onPress: any, children: any, style?: any, loading?: boolean }) {
    return (
        <Pressable
            disabled={disabled}
            onPress={() => {if (!loading) onPress()}}
            style={buildStyles(secondary, disabled, style)}
        >
            {loading ? (
                <ActivityIndicator />
            ) : (
                <Text style={styles.text1}>{children}</Text>
            )}
        </Pressable>
    )
}

const styles = StyleSheet.create({
    text1: {
        color: Colors.WHITE
    },
    submitButton: {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 50,
        height: 40,
        top: 5,
        borderWidth: 2,
    },
    active: {
        backgroundColor: Colors.DEFAULT_COLOR,
        borderColor: Colors.DEFAULT_COLOR
    },
    disabled: {
        backgroundColor: Colors.INACTIVE_TEXT,
        borderColor: Colors.INACTIVE_TEXT
    },
    secondary: {
        backgroundColor: Colors.UNSELECTED_COLOR,
        borderColor: Colors.DEFAULT_COLOR
    }
})

const buildStyles = (secondary, disabled, style) => {
    const stylesToApply: any[] = [style, styles.submitButton]
    if (disabled) {
        stylesToApply.push(styles.disabled)
    } else if (secondary) {
        stylesToApply.push(styles.secondary)
    } else {
        stylesToApply.push(styles.active)
    }
    return stylesToApply
}

export default Button
