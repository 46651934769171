import { useState } from 'react'
import { View, StyleSheet, Text, TextInput } from 'react-native'

import Button from '../components/Button'
import { Colors } from '../styles/Colors'
import { useAppDispatch } from '../store/hooks'
import { createAccount } from '../store/user'


const CreateAccount = ({ closeModal }: { closeModal: Function }) => {
    const dispatch = useAppDispatch()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [createAccountLoading, setCreateAccountLoading] = useState(false)

    const handleCreate = () => {
        setCreateAccountLoading(true)
        dispatch(createAccount(email, password))
    }

    return (
        <View style={styles.createAccountView}>
            <View style={styles.inputContainer}>
                <Text style={[styles.text1, styles.label]}>Email</Text>
                <TextInput
                    style={styles.input}
                    onChangeText={setEmail}
                    value={email}
                />
                <Text style={[styles.text1, styles.label]}>Password</Text>
                <TextInput
                    style={styles.input}
                    onChangeText={setPassword}
                    value={password}
                    secureTextEntry={true}
                />
                <Button
                    disabled={false}
                    onPress={handleCreate}
                    style={styles.createAccountButton}
                    loading={createAccountLoading}
                >
                    Create Account
                </Button>
                <Button
                    disabled={createAccountLoading}
                    onPress={closeModal}
                    style={styles.loginButton}
                    secondary={true}
                >
                    Back to Login
                </Button>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    createAccountView: {
    },
    text1: {
        color: Colors.WHITE
    },
    label: {
        alignItems: 'flex-start',
        width: '75%',
    },
    inputContainer: {
        width: '100%',
        alignItems: 'center',
        marginTop: 50,
    },    
    input: {
        color: Colors.WHITE,
        fontSize: 22,
        borderWidth: 1,
        borderRadius: 6,
        margin: 10,
        padding: 5,
        width: '75%'
    },
    createAccountButton: {
        marginTop: 5,
        marginBottom: 5,
        width: 200
    },
    loginButton: {
        marginTop: 15,
        marginBottom: 5,
        width: 200
    },
})

export default CreateAccount