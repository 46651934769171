  export default `mutation InsertPicks($picksInsertObjects: [picks_insert_input!]!) {
    insert_picks(objects: $picksInsertObjects, on_conflict: {
      constraint: picks_matchup_id_user_id_league_id_key,
      update_columns: [pick_team_id]
    }) {
      returning {
        id
      }
    }
  }
`