import { mutate } from './clients/HasuraClient'
import insertPicksMutation from './graphQL/insertPicksMutation'

export const insertPicks = (picks: any, league_id: String, user_id: String) => {
    return new Promise((res, rej) => {
        const inputArr: any[] = []
        Object.keys(picks).forEach((pickKey: any) => {
            if (picks[pickKey].pickTeamId) {
                inputArr.push({
                    league_id: league_id,
                    matchup_id: pickKey,
                    pick_team_id: picks[pickKey].pickTeamId,
                    user_id: user_id
                })
            }
        })
        mutate(insertPicksMutation, { picksInsertObjects: inputArr }).then((response) => {
            console.log(response)
        })
    })
}

