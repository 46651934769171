import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { fetchCommissionerMatchups } from '../api/matchupsAPI'
import { fetchWeeks } from '../api/leaguesAPI'
import { actions as picksActions } from './picks'
import { actions as leaguesActions } from './leagues'

interface MatchupsStateType {
  hydrated: boolean
  infoByLeague: {
    [leagueId: string]: {
      matchupsByWeek: {
        [week: string]: {
          matchupInfo: {
            leagueWeek: string
            league: string
            matchups: any[]
          }[]
        }
      }
    }
  }
  commissionerMatchups: {
    [week: string]: {
      [leagueKey: string]: {
        matchups: any[]
      }
    }
  }
}

interface SetMatchupsPayloadType {
  leagueId: string
  matchupsByWeek: any
}

// Slice
const slice = createSlice({
  name: 'matchups',
  initialState: {
    hydrated: false,
    infoByLeague: {},
    commissionerMatchups: {}
  },
  reducers: {
    setMatchups: (state: MatchupsStateType, action: PayloadAction<SetMatchupsPayloadType>) => {
      state.hydrated = true
      state.infoByLeague[action.payload.leagueId] = {
        matchupsByWeek: action.payload.matchupsByWeek,
      }
    },
    setCommissionerMatchups: (state: MatchupsStateType, action: PayloadAction<any>) => {
      state.commissionerMatchups = action.payload
    }
  },
})

export default slice.reducer

// Actions
const { setMatchups, setCommissionerMatchups } = slice.actions
const { setPicks } = picksActions
const { setLeagueWeeks } = leaguesActions

export const hydrateMatchups = (userId: string, leagueId: string) => async (dispatch: any) => {
  fetch('https://pbjndoahx0.execute-api.us-east-1.amazonaws.com/getMatchups', {
    method: 'POST',
    body: JSON.stringify({ userId, leagueId })
  })
  .then(r => r.json())
  .then((response) => {
    const { picks, matchupsByWeek } = response
    dispatch(setMatchups({ leagueId, matchupsByWeek }))
    dispatch(setPicks({ leagueId, picks }))
  })
}

export const hydrateCommissionerMatchups = (leagueWeek: string, leagues: string[]) => async (dispatch: any) => {
  fetchCommissionerMatchups(leagueWeek, leagues).then((matchups: any) => {
    const commissionerMatchups = {}
    matchups.forEach((matchup) => {
      if (commissionerMatchups[leagueWeek]) {
        if (commissionerMatchups[leagueWeek][matchup.league]) {
          commissionerMatchups[leagueWeek][matchup.league].matchups.push(matchup)
        } else {
          commissionerMatchups[leagueWeek][matchup.league] = {
            matchups: [matchup]
          }
        }
      } else {
        commissionerMatchups[leagueWeek] = {
          [matchup.league]: {
            matchups: [matchup]
          }
        }
      }
    })
    dispatch(setCommissionerMatchups(commissionerMatchups))
  })
}