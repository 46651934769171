import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { AppDispatch } from './index'
import { fetchPublicLeagues, joinLeagues, fetchLeaguesOverview, fetchWeeks, fetchCommissionerLeagues } from '../api/leaguesAPI'
import { actions as userActions } from './user'

interface LeaguesStateType {
    publicLeagues: PublicLeagueType[]
    standings: {
      [leagueId: string]: StandingsType[]
    }
    userLeagues: {
      [leagueId: string]: LeagueType
    }
}

export interface LeagueType {
  id: string,
  name: string,
  leagueWeek: string,
  weeks?: string[],
  count?: number,
  standings?: StandingsType[],
  commissionerId: string
}

interface PublicLeagueType {
  id: string,
  name: string,
  count: number
}

interface CommissionerLeagueType {
  id: string,
  name: string,
  activeLeagueWeek: string
}

interface StandingsType {
  username: string,
  record: string
}

interface SetStandingsPayloadType {
  leagueId: string
  standings: {
    username: string,
    record: string
  }[]
}

// Slice
const slice = createSlice({
  name: 'leagues',
  initialState: {
    publicLeagues: [],
    standings: {},
    userLeagues: {},
  },
  reducers: {
    setPublicLeagues: (state: LeaguesStateType, action: PayloadAction<PublicLeagueType[]>) => {
      state.publicLeagues = action.payload
    },
    setStandings: (state: LeaguesStateType, action: PayloadAction<any>) => {
      state.standings = action.payload
    },
    setUserLeagues: (state: LeaguesStateType, action: PayloadAction<any>) => {
      state.userLeagues = action.payload.leagues
    },
    setLeagueWeeks: (state: LeaguesStateType, action: PayloadAction<any>) => {
      const { leagueId, weeks } = action.payload
      const newUserLeague = state.userLeagues[leagueId]
      newUserLeague.weeks = weeks
      state.userLeagues[action.payload.leagueId] = newUserLeague
    }
  },
});

export default slice.reducer
export const actions = slice.actions

// Actions
const { setUserLeagues, setPublicLeagues, setStandings, setLeagueWeeks } = slice.actions

export const hydratePublicLeagues = () => (dispatch: AppDispatch) => {
  fetchPublicLeagues().then((response: any) => {
    const formattedLeagues = response.leagues.map((leagueRes: any) => {
        const fLeague = {
            id: leagueRes.id,
            name: leagueRes.name,
            count: leagueRes.users_leagues_aggregate.aggregate.count
        }
        return fLeague
    })
    dispatch(setPublicLeagues(formattedLeagues))
  })
}

// TODO handle matchup week
export const joinLeague = (userId: string, leagueId: string) => (dispatch: AppDispatch) => {
  joinLeagues(userId, leagueId).then((response: any) => {
    const { id, name, active_league_week: leagueWeek, leagues_matchups } = response.insert_users_leagues.returning[0].league
    const weeks = leagues_matchups ? leagues_matchups.map((league_matchup: any) => league_matchup.week_name) : []
    dispatch(setUserLeagues({ leagues: { id: { id, name, leagueWeek, weeks: [... new Set(weeks.sort())] } }}))
  })
}

export const hydrateLeaguesOverview = (userLeagueIds: string[]) => (dispatch: AppDispatch) => {
  fetch('https://pbjndoahx0.execute-api.us-east-1.amazonaws.com/getLeaguesOverview', {
    method: 'POST',
    body: JSON.stringify({ leagueIds: userLeagueIds })
  })
  .then(r => r.json())
  .then((standings) => {
    const publicLeagues = []
    dispatch(setPublicLeagues(publicLeagues))
    dispatch(setStandings(standings))
  })
}

export const hydrateLeagueWeeks = (leagueId: string) => (dispatch: AppDispatch) => {
  fetchWeeks(leagueId).then((response: any) => {
    const weeks = []
    dispatch(setLeagueWeeks({ leagueId, weeks }))
  })
}

// export const hydrateStandings = (leagueId: string) => (dispatch: AppDispatch) => {
//   fetchLeagueStandings(leagueId).then((response: any) => {
//     const standings = response.leagues[0].users_leagues.map((entry: any) => {
//       const { username, picks } = entry.user
//       const record = {
//         wins: 0,
//         losses: 0,
//         pushes: 0
//       }
//       picks.forEach((pick: any) => {
//         if (!pick) return
//         if (pick.outcome === 'WIN') record.wins += 1
//         if (pick.outcome === 'LOSS') record.losses += 1
//         if (pick.outcome === 'PUSH') record.pushes += 1
//       })
//       return {
//         username,
//         record
//       }
//     })
//     standings.sort((a: any, b: any) => {
//       let aWinPct = 0
//       let bWinPct = 0
//       if (a.record.wins + a.record.losses + a.record.pushes > 0) aWinPct = aWinPct = (a.record.wins + a.record.pushes * 0.5) / (a.record.wins + a.record.losses + a.record.pushes)
//       if (b.record.wins + b.record.losses + b.record.pushes > 0) bWinPct = bWinPct = (b.record.wins + b.record.pushes * 0.5) / (b.record.wins + b.record.losses + b.record.pushes)
//       return bWinPct - aWinPct
//     })
//     dispatch(setStandings({ leagueId, standings }))
//   })
// }

