import { query, mutate } from './clients/HasuraClient'
import leagueWeeksQuery from './graphQL/leagueWeeksQuery'
import publicLeaguesQuery from './graphQL/publicLeaguesQuery'
import joinLeagueMutation from './graphQL/joinLeagueMutation'
import leaguesOverviewQuery from './graphQL/leaguesOverviewQuery'
import commissionerViewQuery from './graphQL/commissionerViewQuery'

// TODO should api fns format response data or just return it?
export const fetchWeeks = (leagueId: string) => {
    return new Promise((res, rej) => {
        query(leagueWeeksQuery, { leagueId }).then((response: any) => {
            if (response.data) {
                res(response.data.leagues_matchups.map((lm) => lm.week_name))
            } else if (response.errors) {
                rej(response.errors)
            }
        })
    })
  }

export const fetchPublicLeagues = () => {
    return new Promise((res, rej) => {
        query(publicLeaguesQuery).then((response: any) => {
            if (response.data) {
                res(response.data)
            } else if (response.errors) {
                rej(response.errors)
            }
        })
    })
}

export const joinLeagues = (userId: string, leagueId: string) => {
    return new Promise((res, rej) => {
        mutate(joinLeagueMutation, { userId, leagueId }).then((response: any) => {
            if (response.data) {
                res(response.data)
            } else if (response.errors) {
                rej(response.errors)
            }
        })
    })
}

// export const fetchLeagueStandings = (leagueId: string) => {
//     return new Promise((res, rej) => {
//         query(leagueStandingsQuery, { leagueId }).then((response: any) => {
//             if (response.data) {
//                 res(response.data)
//             } else if (response.errors) {
//                 rej(response.errors)
//             }
//         })
//     })
// }

export const fetchLeaguesOverview = (leagueIds: string[]) => {
    return new Promise((res, rej) => {
        query(leaguesOverviewQuery, { leagueIds }).then((response: any) => {
            if (response.data) {
                res(response.data)
            } else if (response.errors) {
                rej(response.errors)
            }
        })
    })
}

export const fetchCommissionerLeagues = (commissionerId: string) => {
    return new Promise((res, rej) => {
        query(commissionerViewQuery, { commissionerId }).then((response: any) => {
            if (response.data) {
                res(response.data)
            } else if (response.errors) {
                rej(response.errors)
            }
        })
    })
}