import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { insertUser } from '../api/userAPI'
import { joinLeagues } from '../api/leaguesAPI'
import { actions as leaguesActions } from './leagues'
import persistToken from './utils/persistToken'


interface UserStateType {
    id: string
    username: string
    email: string
    needsOnboarding: boolean,
    token: string,
    loggedIn: boolean,
}

interface SetUserPayloadType {
  id: string
  username: string
  email: string
}

const userDefaultState = {
  id: '',
  username: '',
  email: '',
  needsOnboarding: false,
  token: null,
  loggedIn: false
}

// Slice
const slice: any = createSlice({
  name: 'user',
  initialState: userDefaultState,
  reducers: {
    setUser: (state: UserStateType, action: PayloadAction<SetUserPayloadType>) => {
      state.id = action.payload.id
      state.username = action.payload.username
      state.email = action.payload.email
      state.loggedIn = true
    },
    setNeedsOnboarding: (state: UserStateType, action: PayloadAction<boolean>) => {
      state.needsOnboarding = action.payload
    },
    setToken: (state: UserStateType, action: PayloadAction<string>) => {
      state.token = action.payload
    },
    setLoggedOut: (state: UserStateType) => {
      state.token = null
      state.loggedIn = false
    }
  },
});

export default slice.reducer
export const actions = slice.actions

// Actions
const { setUser, setLoggedOut } = slice.actions
const { setUserLeagues } = leaguesActions

//TODO Better Default League Joining
const NCAA_TOURNEY_23_ID = '887795c1-69e2-4156-9084-0cd82a01cf1b'
export const createUser = (username: string, email: string, sub: string) => (dispatch: any) => {
  insertUser(username, email, sub).then((response: any) => {
    const { id: userId } = response.insert_users_one
    joinLeagues(userId, NCAA_TOURNEY_23_ID).then((response: any) => {
      const { id, name, active_league_week: leagueWeek, leagues_matchups } = response.insert_users_leagues.returning[0].league
      const weeks = leagues_matchups ? leagues_matchups.map((league_matchup: any) => league_matchup.week_name) : []
      dispatch(setUser({ userId, username, email }))
      // dispatch(setNeedsOnboarding(true))
      dispatch(setUserLeagues({ leagues: { [id]: { id, name, leagueWeek, weeks: [... new Set(weeks.sort())] } }}))
    })
  })
}

export const login = (username: string, password: string) => (dispatch: any) => {
  if (username && password) {
    fetch('https://pbjndoahx0.execute-api.us-east-1.amazonaws.com/login', {
      method: 'POST',
      body: JSON.stringify({ username, password })
    })
    .then(r => r.json())
    .then((user) => {
      const { id, username, email, leagues, token } = user
      dispatch(setUser({ id, username, email }))
      dispatch(setUserLeagues({ leagues }))
      persistToken(token)
    })
  }
}

export const completeLogin = (token: string) => (dispatch: any) => {
  if (token) {
    fetch('https://pbjndoahx0.execute-api.us-east-1.amazonaws.com/completeLogin', {
      method: 'POST',
      body: JSON.stringify({ token })
    })
    .then(r => r.json())
    .then((user) => {
      if (user.message && user.message.includes('Error')) {
        dispatch(setLoggedOut())
      } else {
        const { id, username, email, leagues } = user
        dispatch(setUser({ id, username, email }))
        dispatch(setUserLeagues({ leagues }))
      }
    })
  }
}

export const logout = () => (dispatch: any) => {
  dispatch(setLoggedOut())
}

export const createAccount = (email: string, password: string) => (dispatch: any) => {
  if (email && password) {
    fetch('https://pbjndoahx0.execute-api.us-east-1.amazonaws.com/createAccount', {
      method: 'POST',
      body: JSON.stringify({ email, password })
    })
    .then(r => r.json())
    .then((data) => {
      const { id, username, email, token } = data.insert_users_one
      dispatch(setUser({ id, username, email }))
      dispatch(setUserLeagues({ leagues: [] }))
      persistToken(token)
    })
  }
}