import * as SecureStore from 'expo-secure-store'

const persistToken = async (token) => {
    try {
        await SecureStore.setItemAsync('atsarenalogintoken', token)
    } catch {
        localStorage.setItem('atsarenalogintoken', token)
    }
  }

export default persistToken