import { Fragment } from 'react'
import { SafeAreaView, StyleSheet } from 'react-native'
import { NavigationContainer } from '@react-navigation/native'

import LoginNav from './LoginNav'
import { Colors } from '../styles/Colors'


function MainContent() {
  return (
    <Fragment>
      <SafeAreaView style={styles.screen}>
          <NavigationContainer>
            <LoginNav />
          </NavigationContainer>
      </SafeAreaView>
    </Fragment>
  )
}

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Colors.MENU_BACKGROUND,
  },
  mainContent: {
    flex: 9,
    marginTop: 50,
    width: '100%',
  },
  menuTab: {
    height: 20,
    width: 20
  }
})

export default MainContent
