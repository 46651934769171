import { useEffect, useState } from 'react'
import { Pressable, Modal, View, SafeAreaView, Text, FlatList, StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'
import Accordion from 'react-native-collapsible/Accordion'

import CommissionerMatchupCard from '../components/CommissionerMatchupCard'
import { useAppDispatch } from '../store/hooks'
import { hydrateCommissionerMatchups } from '../store/matchups'
import { Colors } from '../styles/Colors'
import getNextWed from './utils/getNextWed'

const CommissionerView = ({ navigation, route }: { navigation: any, route: any }) => {
  const dispatch = useAppDispatch()
  const safeLeagueId = route?.params?.id || ''
  const safeLeagueName = route?.params?.name || ''
  const { commissionerMatchups } = useSelector((state: any) => state.matchups)
  const [activeSections, setActiveSections] = useState([])
  const nextWed = getNextWed()
  const activeMatchups = commissionerMatchups[nextWed]
  const leagues = ['NFL', 'NCAAF']

  useEffect(() => {
    if (!activeMatchups) {
      dispatch(hydrateCommissionerMatchups(nextWed, leagues))
    }
  }, [activeMatchups])

  const renderHeader = (section) => (
    <View>
      <Text style={styles.sectionHeaderText}>{section.title}</Text>
    </View>
  )
  const renderContent = (section) => (
    <FlatList
      style={styles.matchupsList}
      data={section.content.matchups}
      renderItem={(matchup: any) => (
        <CommissionerMatchupCard
          matchup={matchup.item}
        />
      )}
      keyExtractor={matchup => matchup.id}
    />
  )
  const updateSections = (activeSections) => {
    setActiveSections(activeSections)
  }
  const sections = leagues.map((league) => {
    return {
      title: league,
      content: activeMatchups ? activeMatchups[league] : []
    }
  })

  console.log(sections)

  return (
    <View style={styles.commissionerView}>
      <Text style={styles.headerText}>{safeLeagueName}</Text>
      <Text style={styles.headerSubtext}>Set Matchups for {nextWed}</Text>
      <Accordion
        sections={sections}
        activeSections={activeSections}
        renderHeader={renderHeader}
        renderContent={renderContent}
        onChange={updateSections}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  commissionerView: {
    backgroundColor: Colors.PRIMARY_BACKGROUND,
    alignItems: 'center',
    flex: 8,
    paddingTop: 10
  },
  headerText: {
    fontSize: 24,
    color: Colors.WHITE
  },
  headerSubtext: {
    fontSize: 16,
    color: Colors.WHITE
  },
  sectionHeaderText: {
    fontSize: 16,
    color: Colors.WHITE
  },
  matchupsList: {
    width: '100%',
  },
})

export default CommissionerView