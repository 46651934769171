import { useEffect, useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'
import { createStackNavigator } from '@react-navigation/stack'
import * as SecureStore from 'expo-secure-store'

import MainTabNav from './MainTabNav'
import Login from './Login'
import { useAppDispatch } from '../store/hooks'
import { completeLogin } from '../store/user'


const Stack = createStackNavigator()

function LoginNav() {
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(true)
    const { loggedIn } = useSelector((state: any) => state.user)

    useEffect(() => {
        // If user is not logged in, check for token
        if (!loggedIn) {
            getToken().then((token) => {
                if (token) {
                    dispatch(completeLogin(token))
                }
                setLoading(false)
            })
        }
    }, [loggedIn])
    
    const getToken = async () => {
        let token = null
        try {
          token = await SecureStore.getItemAsync('atsarenalogintoken')
        } catch {
          token = localStorage.getItem('atsarenalogintoken')
        }
        return token
    }

    if (loading) {
        return (<View />)
    }
    
    return (
        <Stack.Navigator>
            {loggedIn ? (
                <Stack.Screen
                    name="ATS Arena"
                    component={MainTabNav}
                    options={{ headerShown: false }}
                />
            ) : (
                <Stack.Screen
                    name="Login"
                    component={Login}
                    options={{ headerShown: false }}
                />
            )}
        </Stack.Navigator>
    )
}

const styles = StyleSheet.create({
    // drawer: {
    //     backgroundColor: Colors.PRIMARY_BACKGROUND
    // }
})
    
export default LoginNav