import { View, StyleSheet, Image } from 'react-native' 
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'

import LeaguesOverviewNav from './LeaguesOverviewNav'
import Matchups from './Matchups'
import Profile from './Profile'
import UserProfileIcon from '../assets/userprofile.png'
import MatchupsIcon from '../assets/battle.png'
import LeaguesIcon from '../assets/trophy.png'
import { Colors } from '../styles/Colors'


const Tab = createBottomTabNavigator()

function MainTabNav() {
    return (
        <View style={styles.mainContent}>
            <Tab.Navigator screenOptions={() => { return {
            tabBarStyle: {
                backgroundColor: Colors.MENU_BACKGROUND,
                borderTopWidth: 0
            }
            }}}>
            <Tab.Screen
                name="Matchups"
                component={Matchups}
                options={{ 
                  headerShown: false,
                  tabBarIcon: () => (<Image source={MatchupsIcon} style={styles.menuTab} />)
                }}
            />
            <Tab.Screen
                name="Profile"
                component={Profile}
                options={{
                tabBarIcon: () => (<Image source={UserProfileIcon} style={styles.menuTab} />),
                  headerShown: false,
                  tabBarBadge: 2
                }}
            />
            <Tab.Screen
                name="Leagues"
                component={LeaguesOverviewNav}
                options={{
                tabBarIcon: () => (<Image source={LeaguesIcon} style={styles.menuTab} />),
                  headerShown: false,
                  tabBarBadge: 2
                }}
            />
            </Tab.Navigator>
      </View>
    )
}

const styles = StyleSheet.create({
    screen: {
      flex: 1,
      backgroundColor: Colors.MENU_BACKGROUND,
    },
    mainContent: {
      flex: 9,
      width: '100%',
    },
    menuTab: {
      height: 20,
      width: 20
    },
    image: {
      width: 25,
      height: 25
  }
  })

export default MainTabNav